import NiceSelect from "nice-select2";

// Uruchamianie nawigacji
document.addEventListener("DOMContentLoaded", function () {
    const hamburger = document.querySelector(".links__hamburger");
    const headerNav = document.querySelector(".header__nav");
    const closeMenu = document.querySelector(".nav__close");
    const body = document.body;

    // Obsługa kliknięcia na ikonę hamburgera
    hamburger.addEventListener("click", function (event) {
        event.preventDefault();
        hamburger.classList.add("is-active");
        headerNav.classList.remove("hidden");
        body.classList.add("unscroll");
    });

    // Obsługa kliknięcia na zamknięcie menu
    closeMenu.addEventListener("click", function (event) {
        event.preventDefault();
        hamburger.classList.remove("is-active");
        headerNav.classList.add("hidden");
        body.classList.remove("unscroll");
    });
});


// Nawigacja - zarządzanie submenu
document.addEventListener("DOMContentLoaded", function () {
    const parentCategories = document.querySelectorAll(".nav__parent-category");

    parentCategories.forEach(category => {
        category.addEventListener("click", function (event) {
            event.preventDefault();

            // Sprawdź, czy kliknięty element jest już otwarty
            const isAlreadyOpen = category.classList.contains("is-expanded");

            // Zamknij wszystkie otwarte podmenu
            parentCategories.forEach(cat => {
                cat.classList.remove("is-expanded");
                const childMenu = cat.closest(".nav__parent").querySelector(".nav__child");
                if (childMenu) {
                    childMenu.classList.remove("is-visible");
                }
            });

            // Jeśli kliknięty element nie był otwarty, otwórz go
            if (!isAlreadyOpen) {
                category.classList.add("is-expanded");
                const childMenu = category.closest(".nav__parent").querySelector(".nav__child");
                if (childMenu) {
                    childMenu.classList.add("is-visible");
                }
            }
        });
    });
});

// Pobieranie nawigacji mobilnej -> desktop
document.addEventListener("DOMContentLoaded", () => {
    // Pobieranie zawartości z menu nawigacji
    const tyresMenu = document.querySelector("#tyresMenu");
    const rimsMenu = document.querySelector("#rimsMenu");
    const spareMenu = document.querySelector("#spareMenu");

    // Pobieranie docelowych elementów w menu głównym
    const tyresMainMenu = document.querySelector("#tyres");
    const rimsMainMenu = document.querySelector("#rims");
    const spareMainMenu = document.querySelector("#spare");

    // Funkcja kopiująca zawartość i zmieniająca klasy elementów li
    const copyMenuContent = (sourceMenu, targetMenu) => {
        // Skopiuj zawartość HTML
        targetMenu.innerHTML = sourceMenu.innerHTML;

        // Pobierz wszystkie elementy li w targetMenu i zmień ich klasy
        targetMenu.querySelectorAll("li").forEach(item => {
            if (item.classList.contains("nav__child-title")) {
                item.classList.remove("nav__child-title");
                item.classList.add("menu__item-title");
            } else if (item.classList.contains("nav__child-item")) {
                item.classList.remove("nav__child-item");
                item.classList.add("menu__item-child");
            }
        });
    };

    // Kopiowanie zawartości menu i zmiana klas
    copyMenuContent(tyresMenu, tyresMainMenu);
    copyMenuContent(rimsMenu, rimsMainMenu);
    copyMenuContent(spareMenu, spareMainMenu);
});

// Otwieranie menu
document.addEventListener("DOMContentLoaded", () => {
    // Pobieramy wszystkie elementy z klasą menu__item-parent
    const menuParents = document.querySelectorAll(".menu__item-parent");

    menuParents.forEach(menuParent => {
        // Nasłuchiwanie zdarzenia click, aby przełączać klasę 'visible', 'is-active' oraz 'active'
        menuParent.addEventListener("click", (event) => {
            event.preventDefault(); // Zapobiegamy domyślnej akcji linku

            // Zamykamy wszystkie inne otwarte menu i resetujemy strzałki oraz klasy active
            menuParents.forEach(otherMenuParent => {
                if (otherMenuParent !== menuParent) {
                    const otherMenuGroup = otherMenuParent.nextElementSibling;
                    const otherArrow = otherMenuParent.querySelector(".arrow");

                    if (otherMenuGroup && otherMenuGroup.classList.contains("menu__item-group")) {
                        otherMenuGroup.classList.remove("visible");
                    }

                    if (otherArrow) {
                        otherArrow.classList.remove("is-active");
                    }

                    otherMenuParent.classList.remove("active");
                }
            });

            // Otwieramy lub zamykamy kliknięte menu
            const menuGroup = menuParent.nextElementSibling;
            const arrow = menuParent.querySelector(".arrow");

            if (menuGroup && menuGroup.classList.contains("menu__item-group")) {
                menuGroup.classList.toggle("visible");
            }

            if (arrow) {
                arrow.classList.toggle("is-active");
            }

            // Przełączanie klasy 'active' na klikniętym menu__item-parent
            menuParent.classList.toggle("active");
        });
    });

    // Nasłuchiwanie na kliknięcie poza menu w celu zamknięcia otwartych elementów
    document.addEventListener("click", (event) => {
        const isClickInsideMenu = Array.from(menuParents).some(menuParent =>
            menuParent.contains(event.target) ||
            (menuParent.nextElementSibling && menuParent.nextElementSibling.contains(event.target))
        );

        if (!isClickInsideMenu) {
            // Usuwanie klasy 'visible' i resetowanie menu, gdy kliknięto poza menu
            menuParents.forEach(menuParent => {
                const menuGroup = menuParent.nextElementSibling;
                const arrow = menuParent.querySelector(".arrow");

                if (menuGroup && menuGroup.classList.contains("menu__item-group")) {
                    menuGroup.classList.remove("visible");
                }

                if (arrow) {
                    arrow.classList.remove("is-active");
                }

                menuParent.classList.remove("active");
            });
        }
    });
});

// Breadcrumbs
document.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
        const breadcrumbs = document.querySelector(".breadcrumbs");
        if (breadcrumbs) {
            breadcrumbs.scrollTo({
                left: breadcrumbs.scrollWidth,
                behavior: "smooth"
            });
        }
    }, 500);
});

// Dropdowns
const initNiceSelect = (select) => {
    const options = {
        searchable: true,
        searchtext: 'Search',
        selectedtext: ' selected'
    };
    new NiceSelect(select, options);
};

// Funkcja do aktualizacji opcji selecta
const updateSelectOptions = (selectElement, options, labelKey, valueKey, isGrouped = false) => {
    if (!options || options.length === 0) {
        console.warn(`No options found for ${selectElement.id}`);
        return;
    }

    if (isGrouped) {
        // Grupowanie producentów według klasy
        const classGroups = {
            '0': document.createElement('optgroup'),
            '1': document.createElement('optgroup'),
            '2': document.createElement('optgroup')
        };
        classGroups['0'].label = 'Budget';
        classGroups['1'].label = 'Mid-Range';
        classGroups['2'].label = 'Premium';

        options.forEach(option => {
            const optionElement = document.createElement('option');
            optionElement.value = option[valueKey] || option[labelKey];
            optionElement.textContent = option[labelKey] || option[valueKey];

            const classGroup = classGroups[option.class];
            if (classGroup) {
                classGroup.appendChild(optionElement);
            }
        });

        selectElement.appendChild(classGroups['0']);
        selectElement.appendChild(classGroups['1']);
        selectElement.appendChild(classGroups['2']);
    } else {
        options.forEach(option => {
            const optionElement = document.createElement('option');
            optionElement.value = option[valueKey] || option[labelKey];
            optionElement.textContent = option[labelKey] || option[valueKey];
            selectElement.appendChild(optionElement);
        });
    }

    initNiceSelect(selectElement);
};

// Funkcja do załadowania danych z JSON do odpowiednich selectów
const loadTireAndRimData = async () => {
    try {
        // Pobierz dane opon
        const tireResponse = await fetch('https://dev2.dpinternet.pl/ot/tires.json');
        const tireData = await tireResponse.json();

        const tireSelectElements = {
            tireWidth: document.getElementById('tireWidth'),
            tireProfile: document.getElementById('tireProfile'),
            rimDiameter: document.getElementById('rimDiameter'),
            speedIndex: document.getElementById('index[speed]'),
            loadIndex: document.getElementById('index[load]'),
            manufacturer: document.getElementById('manufacturer'),
            productionYear: document.getElementById('productionYear'),
            tyreClass: document.getElementById('tyreClass')
        };

        if (tireSelectElements.tireWidth && tireData.tireWidth) {
            updateSelectOptions(tireSelectElements.tireWidth, tireData.tireWidth, 'width', 'id');
        }

        if (tireSelectElements.tireProfile && tireData.tireProfile) {
            updateSelectOptions(tireSelectElements.tireProfile, tireData.tireProfile, 'width', 'id');
        }

        if (tireSelectElements.rimDiameter && tireData.rimDiameter) {
            updateSelectOptions(tireSelectElements.rimDiameter, tireData.rimDiameter, 'width', 'id');
        }

        if (tireSelectElements.speedIndex && tireData.tireSpeed) {
            updateSelectOptions(tireSelectElements.speedIndex, tireData.tireSpeed, 'description', 'value');
        }

        if (tireSelectElements.loadIndex && tireData.tireWeight) {
            updateSelectOptions(tireSelectElements.loadIndex, tireData.tireWeight, 'description', 'value');
        }

        if (tireSelectElements.manufacturer && tireData.producerList) {
            updateSelectOptions(tireSelectElements.manufacturer, tireData.producerList, 'value', 'id', true);
        }

        if (tireSelectElements.productionYear && tireData.tireYear) {
            updateSelectOptions(tireSelectElements.productionYear, tireData.tireYear, 'value', 'id');
        }

        if (tireSelectElements.tyreClass && tireData.tireClass) {
            updateSelectOptions(tireSelectElements.tyreClass, tireData.tireClass, 'value', 'id');
        }

        // Pobierz dane felg
        const rimResponse = await fetch('https://dev2.dpinternet.pl/ot/rims.json');
        const rimData = await rimResponse.json();

        const rimSelectElements = {
            rimSize: document.getElementById('rimsWidth'),
            rimDiameter: document.getElementById('rimsDiameter'),
            rimSpacing1: document.getElementById('rimsScrews'),
            rimSpacing2: document.getElementById('rimsBolt'),
            et: document.getElementById('et'),
            ml: document.getElementById('ml'),
            rimProducer: document.getElementById('rimProducer'),
            rimType: document.getElementById('rimType')
        };

        if (rimSelectElements.rimSize && rimData.rimSize) {
            updateSelectOptions(rimSelectElements.rimSize, rimData.rimSize, 'width', 'id');
        }

        if (rimSelectElements.rimDiameter && rimData.rimDiameter) {
            updateSelectOptions(rimSelectElements.rimDiameter, rimData.rimDiameter, 'width', 'id');
        }

        if (rimSelectElements.rimSpacing1 && rimData.rimSpacing1) {
            updateSelectOptions(rimSelectElements.rimSpacing1, rimData.rimSpacing1, 'width', 'id');
        }

        if (rimSelectElements.rimSpacing2 && rimData.rimSpacing2) {
            updateSelectOptions(rimSelectElements.rimSpacing2, rimData.rimSpacing2, 'width', 'id');
        }

        if (rimSelectElements.et && rimData.et) {
            updateSelectOptions(rimSelectElements.et, rimData.et, 'width', 'id');
        }

        if (rimSelectElements.ml && rimData.ml) {
            updateSelectOptions(rimSelectElements.ml, rimData.ml, 'width', 'id');
        }

        if (rimSelectElements.rimProducer && rimData.producerList) {
            updateSelectOptions(rimSelectElements.rimProducer, rimData.producerList, 'value', 'id');
        }

        if (rimSelectElements.rimType && rimData.rimType) {
            updateSelectOptions(rimSelectElements.rimType, rimData.rimType, 'value', 'id');
        }

    } catch (error) {
        console.error('Błąd podczas ładowania danych z JSON:', error);
    }
};

// Ładowanie danych po załadowaniu DOM
document.addEventListener('DOMContentLoaded', () => {
    loadTireAndRimData();
});
